import myAxios from "./axios_news"


export const readFile = params => {
  return myAxios({
    url: "/file/readFile",
    data: params,
    method: 'post',
    headers: {
      'content-type': "multipart/form-data",
    }
  })
};


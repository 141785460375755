<template>
  <div>
    <img :src="imgUrl"/>
    <el-upload
      class="upload-demo"
      action=""
      :http-request="customUpload"
      :file-list="fileList">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
  </div>
</template>

<script>
import { readFile } from '../api/consactor';
export default {
  data() {
    return {
      imgUrl: '',
      fileList:[]
    }
  },
  methods: {
    initData(){},
    customUpload(file) {
      console.log(file)
      // return
      let formData = new FormData()
      formData.append('files', file.file)
      readFile(formData).then(res=>{
        this.imgUrl = res.data
      })
    }
  }
}
</script>

<style>

</style>